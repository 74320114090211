import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { permissionAbility } from "@/helpers/helpers";
import { permissionsConstant } from "@/helpers/permissionsConstant";
import authRouter from "./authRouter";
import axios from "@/helpers/axios";
import leadManagementRouter from "./leadManagementRouter";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/admin/dashboard",
      name: "admin-dashboard",
      component: () => import("@/views/admin/AdminDashboardView.vue"),
      meta: {
        gate: permissionsConstant.DASHBOARD_ACCESS,
        requiresAuth: true,
        pageTitle: "Dashboard",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
            to: "",
          },
        ],
      },
    },
    {
      path: "/admin/users",
      name: "admin-users",
      component: () => import("@/views/admin/users/UsersView.vue"),
      meta: {
        gate: permissionsConstant.USERS_ACCESS,
        requiresAuth: true,
        pageTitle: "Users",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Users",
            active: true,
            to: "",
          },
        ],
      },
    },
    {
      path: "/admin/user/profile",
      name: "user-profile",
      component: () => import("@/views/admin/profile/ProfileView.vue"),
      meta: {
        gate: permissionsConstant.USERS_PROFILE_SHOW,
        requiresAuth: true,
        pageTitle: "Profile",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
            to: "",
          },
        ],
      },
    },

    {
      path: "/admin/user/api",
      name: "user-api",
      component: () => import("@/views/admin/api/ApiView.vue"),
      meta: {
        gate: permissionsConstant.USERS_PROFILE_SHOW,
        requiresAuth: true,
        pageTitle: "Google Api",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Google Api",
            active: true,
            to: "",
          },
        ],
      },
    },
    {
      path: "/admin/roles",
      name: "admin-roles",
      component: () => import("@/views/admin/roles/RolesView.vue"),
      meta: {
        gate: permissionsConstant.ROLES_ACCESS,
        requiresAuth: true,
        pageTitle: "Roles",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Roles",
            active: true,
            to: "",
          },
        ],
      },
    },
    {
      path: "/admin/roles/:id",
      name: "admin-roles-permissions",
      component: () => import("@/views/admin/roles/RolePermissionsView.vue"),
      meta: {
        gate: permissionsConstant.ROLES_SHOW,
        requiresAuth: true,
        pageTitle: "Permissions",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Roles",
            active: false,
            to: "/admin/roles",
          },
          {
            text: "Permissions",
            active: true,
            to: "",
          },
        ],
      },
    },

    {
      path: "/api/campaign-leads/:id",
      name: "campaign-leads-info",
      component: () => import("@/views/admin/list-of-lead/LeadsInfo.vue"),
      meta: {
        gate: permissionsConstant.LEADS_SHOW,
        requiresAuth: true,
        pageTitle: "Leads",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Leads",
            active: false,
            to: "",
          },
        ],
      },
    },
    {
      path: "/campaign-leads/all",
      name: "all-leads-info",
      component: () => import("@/views/admin/list-of-lead/allLeadsInfo.vue"),
      meta: {
        gate: permissionsConstant.LEADS_SHOW,
        requiresAuth: true,
        pageTitle: "All Leads",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Leads",
            active: false,
            to: "",
          },
        ],
      },
    },

    {
      path: "/api/lead-details/:id",
      name: "lead-details",
      component: () => import("@/views/admin/lead-details/LeadDetails.vue"),
      meta: {
        gate: permissionsConstant.LEAD_DETAILS,
        requiresAuth: true,
        pageTitle: "Leads",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Lead Details",
            active: false,
            to: "",
          },
        ],
      },
    },

    {
      path: "/admin/campaigns",
      name: "admin-campaigns",
      component: () => import("@/views/admin/campaign/CampaignView.vue"),
      meta: {
        gate: permissionsConstant.CAMPAIGN_ACCESS,
        requiresAuth: true,
        pageTitle: "Campaigns",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Campaigns",
            active: true,
            to: "",
          },
        ],
      },
    },

    {
      path: "/admin/designation",
      name: "admin-designation",
      component: () => import("@/views/admin/designation/DesignationView.vue"),
      meta: {
        gate: permissionsConstant.DESIGNATION_ACCESS,
        requiresAuth: true,
        pageTitle: "Designation",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Designation",
            active: true,
            to: "",
          },
        ],
      },
    },

    {
      path: "/admin/lead-generator",
      name: "admin-lead-generator",
      component: () =>
        import("@/views/admin/lead-generator/LeadGeneratorView.vue"),
      meta: {
        gate: permissionsConstant.LEAD_GENERATOR_ACCESS,
        requiresAuth: true,
        pageTitle: "Find Leads",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Find Leads",
            active: true,
            to: "",
          },
        ],
      },
    },
    {
      path: "/admin/campaign-information",
      name: "campaign-information",
      component: () => import("@/views/admin/list-of-lead/ListOfLead.vue"),
      meta: {
        gate: permissionsConstant.LIST_OF_LEAD_ACCESS,
        requiresAuth: true,
        pageTitle: "Campaign Information",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Campaign Information",
            active: true,
            to: "",
          },
        ],
      },
    },
    {
      path: "/api/users/all",
      name: "all-users",
      component: () => import("@/views/admin/users/UsersView.vue"),
      meta: {
        gate: permissionsConstant.USERS_ACCESS,
        requiresAuth: true,
        pageTitle: "All Users",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "All Users",
            active: false,
            to: "",
          },
        ],
      },
    },
    {
      path: "/pricing",
      name: "pricing-plan",
      component: () => import("@/views/admin/subscription/Subscription.vue"),
      meta: {
        gate: permissionsConstant.LIST_OF_LEAD_ACCESS,
        requiresAuth: true,
        pageTitle: "Pricing Plan",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Pricing Plan",
            active: true,
            to: "",
          },
        ],
      },
    },
    {
      path: "/payment-histories",
      name: "payment-histories",
      component: () =>
        import("@/views/admin/subscription/PaymentHistoriesView.vue"),
      meta: {
        gate: permissionsConstant.LIST_OF_LEAD_ACCESS,
        requiresAuth: true,
        pageTitle: "Payment Histories",
        homePatch: "/admin/dashboard",
        breadcrumb: [
          {
            text: "Payment Histories",
            active: true,
            to: "",
          },
        ],
      },
    },
    ...leadManagementRouter,
    ...authRouter,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// global auth guard
router.beforeEach(async (to, from, next) => {
  // dynamic title
  window.document.title =
    to.meta && to.meta?.pageTitle
      ? `${process.env.VUE_APP_TITLE} | ${to.meta?.pageTitle}`
      : `${process.env.VUE_APP_TITLE}`;

  const isAuthenticated = store.getters["authModule/getIsAuthenticated"];

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      try {
        // get user  permission
        const resUser = await axios.post("/api/user?include=permissions");

        const { permissions } = resUser?.data?.data;
        const mapPermissions = permissions?.data.map((item) => item.name);

        await store.dispatch("userModule/setPermissions", {
          permissions: mapPermissions,
        });

        // permission page
        if (to.matched.some((record) => record.meta.gate)) {
          const permissions = store.getters["userModule/getPermissions"];
          if (permissionAbility(to.meta.gate, permissions)) {
            next();
          } else {
            next(false);
          }
        } else {
          next(false);
        }
      } catch (error) {
        await store.dispatch("authModule/setIsAuthenticated", {
          isAuthenticated: false,
          token: null,
        });

        await store.dispatch("authModule/clearPersistedState");

        await store.dispatch("userModule/removeUser");

        await store.dispatch("userModule/setPermissions", {
          permissions: null,
        });

        window.location.href = "/";
      }
    } else {
      next({ name: "login" });
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (isAuthenticated) {
      next({ name: "admin-dashboard" });
    } else {
      next();
    }
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
